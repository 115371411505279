import { Application } from "@hotwired/stimulus"
import { Autocomplete } from "stimulus-autocomplete"

const application = Application.start()
application.register('autocomplete', Autocomplete)


// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

// Import and register all TailwindCSS Components
// import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
// application.register('alert', Alert)
// application.register('autosave', Autosave)
// application.register('dropdown', Dropdown)
// application.register('modal', Modal)
// application.register('tabs', Tabs)
// application.register('popover', Popover)
// application.register('toggle', Toggle)
// application.register('slideover', Slideover)

// function setInputFilter(textbox, inputFilter, errMsg) {
//   ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout"].forEach(function(event) {
//     textbox.addEventListener(event, function(e) {
//       if (inputFilter(this.value)) {
//         // Accepted value
//         if (["keydown","mousedown","focusout"].indexOf(e.type) >= 0){
//           this.classList.remove("input-error");
//           this.setCustomValidity("");
//         }
//         this.oldValue = this.value;
//         this.oldSelectionStart = this.selectionStart;
//         this.oldSelectionEnd = this.selectionEnd;
//       } else if (this.hasOwnProperty("oldValue")) {
//         // Rejected value - restore the previous one
//         this.classList.add("input-error");
//         this.setCustomValidity(errMsg);
//         this.reportValidity();
//         this.value = this.oldValue;
//         this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
//       } else {
//         // Rejected value - nothing to restore
//         this.value = "";
//       }
//     });
//   });
// }

// setInputFilter(document.getElementById("intTextBox"), function(value) {
//   return /^-?\d*$/.test(value); 
// }, "Must be an integer");