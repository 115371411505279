import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "name",
    "price"
  ]

  connect() {
    // console.log(this.nameTarget.innerHTML)
  }

  changeCoupon() {
    document.getElementById("couponPharmName").innerHTML = this.nameTarget.innerHTML
    document.getElementById("couponPrice").innerHTML = this.priceTarget.innerHTML
  }
}
